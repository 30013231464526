<template>
  <div>
    <div v-if="show" id="invoice" :class="{[type]: true}" class="invoice-box">

      <table :direction="lang == 'en'? 'ltr':''" :style="lang == 'en'? lang_dir:{}" class="report-container">
        <thead :class="is_repeated_header ? 'report-header' : 'no-report-header'">
        <tr>
          <th class="report-header-cell">
            <div class="header-info">
              <main-header :data-header="template" :inner-data="data" :print-type="type"></main-header>
            </div>
          </th>
        </tr>
        </thead>
        <tbody class="report-content">
        <tr>
          <td class="report-content-cell">
            <div class="main">
              <div class="d-flex">
                <div v-if="inContentTemp('purchase_code') && data.purchase_code" class="w-100">
                  <div class="green-cc">
                    <h5 class="color-1a5a4a mb-1 f-s-15px d-flex justify-content-between"><b>رقم الفاتورة</b> <b
                        v-if="is_multi_language_label" style="display: block; direction: ltr;">Invoice No.</b></h5>
                    <p class="mb-0 space-latter-2">{{ data.purchase_code }}</p>
                  </div>
                </div>
<!--                <div v-if="inContentTemp('reference_number') && data.reference_number" class="ml-2 mr-2 w-100">-->
<!--                  <div class="green-cc">-->
<!--                    <h5 class="color-1a5a4a mb-1 f-s-15px d-flex justify-content-between"><b>الرقم المرجعي</b> <b-->
<!--                        v-if="is_multi_language_label" style="display: block; direction: ltr;">PO No.</b></h5>-->
<!--                    <p class="mb-0 space-latter-2">{{ data.reference_number }}</p>-->
<!--                  </div>-->
<!--                </div>-->
                <div v-if="inContentTemp('purchase_date') && data.purchase_date" class="ml-2 mr-2 w-100">
                  <div class="green-cc">
                    <h5 class="color-1a5a4a mb-1 f-s-15px d-flex justify-content-between"><b>تاريخ الفاتورة</b> <b
                        v-if="is_multi_language_label" style="display: block; direction: ltr;">Invoice date</b></h5>
                    <p class="mb-0 space-latter-2">{{ data.purchase_date }}</p>
                  </div>
                </div>
                <div v-if="inContentTemp('issue_date') && data.issue_date" class="ml-2 mr-2 w-100">
                  <div class="green-cc">
                    <h5 class="color-1a5a4a mb-1 f-s-15px d-flex justify-content-between"><b>تاريخ الإصدار</b> <b
                        v-if="is_multi_language_label" style="display: block; direction: ltr;">Issue date</b></h5>
                    <p class="mb-0 space-latter-2">{{ data.issue_date }}</p>
                  </div>
                </div>
<!--                <div v-if="inContentTemp('is_deferred')" class="ml-2 mr-2 w-100">-->
<!--                  <div class="green-cc">-->
<!--                    <h5 class="color-1a5a4a mb-1 f-s-15px d-flex justify-content-between"><b>نوع الفاتورة</b> <b-->
<!--                        v-if="is_multi_language_label" style="display: block; direction: ltr;">Invoice type</b></h5>-->
<!--                    <p v-if="data.is_deferred" class="mb-0 space-latter-2">آجل</p>-->
<!--                    <p v-else class="mb-0 space-latter-2">نقدي</p>-->
<!--                  </div>-->
<!--                </div>-->
              </div>

              <div class="d-flex" style="min-height: 106px;">
                <div class="mt-2 w-100 margin-left-025rem">
                  <div class="d-flex justify-content-between">
                    <span class="color-1a5a4a f-s-15px mb-1"><b>معلومات المــــورد</b></span>
                    <span v-if="is_multi_language_label" class="color-1a5a4a f-s-15px mb-1"><b>Supplier information</b></span>
                  </div>

                  <div class="w-60per h-100">
                    <div class="green-cc-3">
                      <div class="d-flex">
                        <span class="par-h"></span>
                        <div>
                          <p v-if="inContentTemp('business_name_ar') || inContentTemp('business_name_en')" class="f-s-15px mb-1">
                            {{ data.supplier ? data.supplier.business_name : '' }}</p>
                          <p v-if="data.supplier" class="f-s-15px mb-0">
                            <span v-if="inContentTemp('supplier_country_name')">
                              {{(data.supplier.country_name ? ' - ' + data.supplier.country_name : '')}}
                            </span>
                            <span v-if="inContentTemp('supplier_city_name')">
                              {{(data.supplier.city_name ? ' - ' + data.supplier.city_name : '')}}
                            </span>
                            <span>{{ (data.supplier.address_1 ? ' - ' + data.supplier.address_1 : '') }}</span>
                          </p>
                          <p v-if="data.supplier" class="f-s-15px mb-0">
                            <span style="unicode-bidi:plaintext">{{data.supplier.mobile ? data.supplier.mobile : '' }}</span>
                            <span>{{ data.supplier.email ? ' - ' + data.supplier.email : '' }}</span>
                          </p>
                        </div>
                      </div>

                    </div>
                  </div>

                </div>
                <div class="mt-2 w-100 margin-right-025rem">
                  <div class="d-flex justify-content-between">
                    <span class="color-1a5a4a f-s-15px mb-1"><b>معلومات الشركة</b></span>
                    <span v-if="is_multi_language_label" class="color-1a5a4a f-s-15px mb-1"><b>Company information</b></span>
                  </div>
                  <div class="w-60per h-100">
                    <div class="green-cc-3">
                      <div class="d-flex">
                        <span class="par-h"></span>
                        <div>
                          <p class="f-s-15px mb-1" v-if="inContentTemp('business_name_ar')">{{ data.company ? data.company.business_name : '' }}</p>
                          <p class="f-s-15px mb-1" v-if="inContentTemp('business_name_en')">{{ data.company ? data.company.business_name_en : '' }}</p>
                          <p class="f-s-15px mb-0" v-if="data.company">
                            <span v-if="inContentTemp('cocompany_mpany_country_name')">{{data.company.country_name}}</span>
                            <span v-if="inContentTemp('cocompany_mpany_city_name')">{{(data.company.city_name ? ' - ' + data.company.city_name : '')}}</span>
                            <span>{{(data.company.address_1 ? ' - ' + data.company.address_1 : '')}}</span>
                          </p>
                        </div>
                      </div>
                      <div v-if="data.company && (data.company.business_record || data.company.tax_no)">
                        <h5 class="f-s-15px mb-0" v-if="data.company.business_record && inContentTemp('business_record')">
                          <p class="color-1a5a4a mb-1 d-flex justify-content-between"><span>رقم السجل التجــــاري</span> <span>{{ data.company ? data.company.business_record : '' }}</span> <span style="display: block; direction: ltr;" v-if="is_multi_language_label">CR No.</span></p>
                          <!--                          <p class="mb-0 f-s-15px">{{ data.company ? data.company.business_record : '' }}</p>-->
                        </h5>
                        <h5 class="f-s-15px mb-0" v-if="data.company.tax_no && inContentTemp('tax_no')">
                          <p class="color-1a5a4a mb-1 d-flex justify-content-between"><span>رقم التسجيل الضريبي</span>  <span>{{ data.company ? data.company.tax_no : '' }}</span> <span style="display: block; direction: ltr;" v-if="is_multi_language_label">VAT No.</span></p>
                          <!--                          <p class="mb-0 f-s-15px">{{ data.company ? data.company.tax_no : '' }}</p>-->
                        </h5>
                      </div>

                    </div>
                  </div>
                </div>
              </div>

              <div v-if="data.notes" class="mt-2 d-flex">
                <div class="w-60per">
                  <div class="green-cc-3" style="height: auto;">
                    <span class="color-1a5a4a f-s-15px mb-4 d-flex justify-content-between"><b>الملاحظات</b> <b
                        v-if="is_multi_language_label">Notes</b></span>
                    <p class="f-s-15px mb-1">{{ data.notes }}</p>
                  </div>
                </div>
              </div>
              <div class="mt-2 table-border-radius break-inside-revert">
                <table class="table" style="margin: 0;text-align: center;">
                  <thead>
                  <tr style="background:#ffffff">
                    <th v-if="inProductTableSetting('serial_number')">#</th>
                    <th style="width: 179px;"> المنتج <span v-if="is_multi_language_label" style="display: block;">Item</span></th>
                    <th> ســعر الوحدة<span v-if="is_multi_language_label" style="display: block; direction: ltr;">U. Rate</span></th>
                    <th>الكمية <span v-if="is_multi_language_label" style="display: block;">QTY</span></th>
                    <!--                    <th>الوحدة <span style="display: block;" v-if="is_multi_language_label">Unit</span></th>-->
                    <th v-if="inProductTableSetting('unit')">الوحدة <span v-if="is_multi_language_label"
                                                                          style="display: block;">Unit</span>
                    </th>
                    <th v-if="inProductTableSetting('discount_calc')">خصومات <span v-if="is_multi_language_label"
                                                                                   style="display: block;">Discounts</span>
                    </th>
                    <th v-if="inProductTableSetting('subtotal_before_discount')"> المجموع قبل الخصم<span
                        v-if="is_multi_language_label" style="display: block;">Total Before Discount</span></th>
                    <th v-if="inProductTableSetting('subtotal_after_discount')"> المجموع بعد الخصم<span
                        v-if="is_multi_language_label" style="display: block;">Total After Discount</span></th>
                    <th v-if="inProductTableSetting('subtotal_before_tax')"> المجموع قبل VAT<span
                        v-if="is_multi_language_label" style="display: block;">Total Before VAT</span></th>
                    <th v-if="inProductTableSetting('tax_applied')"> نــسـبـة الضريبة <span
                        v-if="is_multi_language_label"
                        style="display: block;">VAT</span>
                    </th>
                    <th v-if="inProductTableSetting('amount_tax')"> قــيــمـة الضريبة <span
                        v-if="is_multi_language_label"
                        style="display: block;">VAT
                      Value</span></th>
                    <th v-if="inProductTableSetting('subtotal_after_tax')"> المجموع بعد VAT<span
                        v-if="is_multi_language_label"
                        style="display: block;">Total
                      After VAT</span></th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(row, index) in items_list" :key="'tr'+index" class="tr">
                    <td v-if="inProductTableSetting('serial_number')">{{ index + 1 }}</td>
                    <td style="overflow: hidden;">
                      <!-- <span style="width:50px;display:block;word-wrap: unset;white-space: nowrap;text-overflow: ellipsis;"> -->
                      <span style="width: 150px; display: block; word-wrap: break-word; white-space: normal;">
                        {{ row.item.name }}
                      </span>
                    </td>
                    <td>{{ row.unit_price }}</td>
                    <td>{{ row.qty }}</td>
                    <!--                    <td>{{ (row.unit && row.unit.name) ? row.unit.name: '' }}</td>-->
                    <td v-if="inProductTableSetting('unit')">{{ row.unit_name }}</td>
                    <td v-if="inProductTableSetting('discount_calc')">{{ row.discount_calc }}</td>
                    <td v-if="inProductTableSetting('subtotal_before_discount')">{{ row.subtotal_before_discount }}</td>
                    <td v-if="inProductTableSetting('subtotal_after_discount')">{{ row.subtotal_after_discount }}</td>
                    <td v-if="inProductTableSetting('subtotal_before_tax')">{{ row.subtotal_before_tax }}</td>
                    <td v-if="inProductTableSetting('tax_applied')">
                      <template v-if="row.tax_applied.length > 0">
                        <span v-for="(_row, index) in row.tax_applied"
                              :key="'tax_applied'+index">{{
                            _row.percentage ? parseToShortFloat(_row.percentage) : '0'
                          }}
                          <span v-if='row.tax_applied.length > 1 && (row.tax_applied.length -1) == index'> , </span>
                        </span>
                      </template>
                      <template v-else-if="row.tax_applied.length == 0 && row.amount_tax > 0 && row.tax">
                        <span> 15 </span>
                      </template>
                      <span v-else> 0 </span>
                    </td>
                    <td v-if="inProductTableSetting('amount_tax')">{{ row.amount_tax }}</td>
                    <td v-if="inProductTableSetting('subtotal_after_tax')">{{
                        row.subtotal_after_tax ? row.subtotal_after_tax : getSubtotalAfterTax({
                          unit_price: row.unit_price,
                          qty: row.qty,
                          amount_excluded_tax: row.amount_excluded_tax
                        })
                      }}
                    </td>
                  </tr>
                  <template v-if="items_list && items_list.length < 9 && is_empty_row">
                    <tr v-for="(row, index) in (8 - items_list.length)" v-if="items_list.length < 9" :key="'trEmpty'+index">
                      <td v-if="inProductTableSetting('serial_number')"></td>
                      <td style="height: 33px;"></td>
                      <td></td>
                      <td></td>
                      <td v-if="inProductTableSetting('unit')"></td>
                      <td v-if="inProductTableSetting('discount_calc')"></td>
                      <td v-if="inProductTableSetting('subtotal_before_discount')"></td>
                      <td v-if="inProductTableSetting('subtotal_after_discount')"></td>
                      <td v-if="inProductTableSetting('subtotal_before_tax')"></td>
                      <td v-if="inProductTableSetting('tax_applied')"></td>
                      <td v-if="inProductTableSetting('amount_tax')"></td>
                      <td v-if="inProductTableSetting('subtotal_after_tax')"></td>
                    </tr>
                  </template>

                  </tbody>
                </table>
              </div>


              <!--              <div class="d-flex justify-content-between mt-2" :class="{'page-break-inside-pdf': type == 'pdf'}">-->
              <div class="d-flex justify-content-between mt-2 page-break-inside-pdf">
                <div style="width: 100%;">
                  <div v-if="inContentTemp('invoice_subtotal')" class="total-border d-flex justify-content-between">
                    <div class="padding-5-20">
                      <h3 class="mb-0 rtl-dir color-1a5a4a width-100 d-flex f-s-15px"><span>المجموع</span> <span
                          v-if="is_multi_language_label"
                          style="display: block; direction: ltr; padding: 0 15px;text-align: left;">Total Before
                        VAT</span></h3>
                    </div>
                    <div class="padding-5-20 d-flex f-s-15px"><span class="sum-sum"><b
                        class="f-s-15px">{{ formatNumber(data.invoice_total_before_taxes) }}</b></span> <span
                        class="color-1a5a4a">{{ data.currency_name }}</span></div>
                  </div>
                  <div v-if="inContentTemp('invoice_total_discount')"
                       class="total-border d-flex mt-1 justify-content-between">
                    <div class="padding-5-20">
                      <h3 class="mb-0 rtl-dir color-1a5a4a width-100 d-flex f-s-15px"><span>مجموع الخصومات</span> <span
                          v-if="is_multi_language_label"
                          style="display: block; direction: ltr; padding: 0 15px;text-align: left;">Total
                        Discount</span></h3>
                    </div>
                    <div class="padding-5-20 d-flex f-s-15px"><span class="sum-sum"><b
                        class="f-s-15px">{{ data.invoice_total_discount }}</b></span> <span
                        class="color-1a5a4a">{{ data.currency_name }}</span></div>
                  </div>
                  <div v-if="inContentTemp('shipping_fees')" class="total-border d-flex mt-1 justify-content-between">
                    <div class="padding-5-20">
                      <h3 class="mb-0 rtl-dir color-1a5a4a width-100 d-flex f-s-15px"><span>مجموع الشحن</span> <span
                          v-if="is_multi_language_label"
                          style="display: block; direction: ltr; padding: 0 15px;text-align: left;">Total
                        Shipping</span></h3>
                    </div>
                    <div class="padding-5-20 d-flex f-s-15px"><span class="sum-sum"><b
                        class="f-s-15px">{{ data.shipping_fees }}</b></span> <span
                        class="color-1a5a4a">{{ data.currency_name }}</span></div>
                  </div>

                  <div v-if="inContentTemp('invoice_total_taxes')"
                       class="total-border d-flex mt-1 justify-content-between">
                    <div class="padding-5-20">
                      <h3 class="mb-0 rtl-dir color-1a5a4a width-100 d-flex f-s-15px"><span>ضريبة القيمة المضافة
                        15%</span> <span v-if="is_multi_language_label"
                                         style="display: block; direction: ltr; padding: 0 15px;text-align: left;">VAT
                        15%</span></h3>
                    </div>
                    <div class="padding-5-20 d-flex f-s-15px"><span class="sum-sum"><b
                        class="f-s-15px">{{ data.invoice_total_taxes }}</b></span> <span
                        class="color-1a5a4a">{{ data.currency_name }}</span></div>
                  </div>
                  <div v-if="inContentTemp('invoice_total_after_taxes')"
                       class="total-border d-flex mt-1 justify-content-between">
                    <div class="padding-5-20">
                      <h3 class="mb-0 rtl-dir color-1a5a4a width-100 d-flex f-s-15px"><span>المجموع مع الضريبة
                        15%</span> <span v-if="is_multi_language_label"
                                         style="display: block; direction: ltr; padding: 0 15px;text-align: left;">Total
                        with VAT 15%</span></h3>
                    </div>
                    <div class="padding-5-20 d-flex f-s-15px"><span class="sum-sum"><b
                        class="f-s-15px">{{ formatNumber(data.invoice_total) }}</b></span> <span
                        class="color-1a5a4a">{{ data.currency_name }}</span></div>
                  </div>

                  <div v-if="is_payment_details && data.payments && data.payments.length" class="mt-1 d-flex">
                    <div class="w-60per">
                      <div class="green-cc-3" style="padding: 2px 5px;height: 100%;">
                        <h5 class="color-1a5a4a f-s-15px mb-2 d-flex justify-content-between"><b>المدفوعات</b> <b
                            v-if="is_multi_language_label">Payments</b></h5>
                        <div>
                          <h6 v-for="(row, index) in data.payments" :key="index"
                              style="display: inline-block;margin-left: 5px;"> {{ row.payment_method }}
                            {{ formatNumber(row.amount) }} {{
                              row.currency_name
                            }} </h6>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
<!--                <div v-if="data.status != 1 && type == 'print'" class="text-center total-border"-->
<!--                     style="padding: 5px 4px;width: 261px; height: fit-content;">-->
<!--                  <qr-code v-if="data.data_qr_code" :size="size" :text="data.data_qr_code"-->
<!--                           class="text-center"></qr-code>-->
<!--                </div>-->
              </div>
              <main-footer :data-footer="template" :inner-data="data"></main-footer>
            </div>
          </td>
        </tr>
        </tbody>
        <tfoot :class="is_repeated_footer ? 'report-footer' : 'no-report-footer'">
        <tr>
          <td class="report-footer-cell">
            <div :class="{'page-break-inside-pdf': type == 'pdf'}" class="footer-info footer-print">
              <div v-if="data.pos_policy">
                <p v-if="is_break_before_footer"><span
                    :style="'border: 1px solid;width: 70%;height: 0px;display: block;margin: 20px auto 0;border-color:'+footer_font_color"></span>
                </p>
                <div class="mb-0 ql-editor" style="float: right;text-align: right;width: 100%;height: auto;padding: 0;"
                     v-html="data.pos_policy"></div>
              </div>
            </div>
          </td>
        </tr>
        </tfoot>
      </table>


    </div>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";

import VueQRCodeComponent from 'vue-qrcode-component';
import MainHeader from "@/view/content/printing-heads/MainHeader.vue";
import html2pdf from "html2pdf.js";
import MainFooter from "@/view/content/printing-footers/MainFooter.vue";
import dataTypeNumbers from "@/core/config/mix/dataTypeNumbers";

export default {
  name: "purchase-invoices",
  components: {
    MainFooter,
    MainHeader,
    'qr-code': VueQRCodeComponent
  },

  data() {
    return {
      lang: this.$i18n.locale,
      lang_dir: {'direction': 'ltr', 'text-align': 'left'},
      mainRoute: 'purchases/purchases-invoices',

      data: {},
      items_list: [],

      templateMainRoute: 'settings/print_setting/type',
      template_for: 'PurchaseInvoice',
      template: {},
      content_temp: [],
      product_table_setting: [],

      idEditing: this.$route.params.purchase_invoice_id ? this.$route.params.purchase_invoice_id : null,
      status: this.$route.params.status ? this.$route.params.status : null,
      items_total: 0,
      total: 0,
      show: false,
      is_show_price: (this.$route.query.is_show_price == 0) ? false : true,
      type: this.$route.params.type ? this.$route.params.type : null,
      size: 250,
      is_multi_language_label: true,

      is_break_before_footer: false,
      is_payment_details: false,
      is_banking_details: false,
      footer_font_color: '#000',
      is_repeated_header: false,
      is_repeated_footer: false,
      is_empty_row: false,
      is_signature: false,
    }
  },
  computed: {
    fileName: function () {
      let _invoice_label = this.$i18n.locale == 'ar' ? 'فاتورة مشتريات' : 'Purchase invoices';
      let _customer_name = (this.data.supplier && this.data.supplier.business_name) ? this.data.supplier.business_name : '';
      let _invoice_code = (this.data.purchase_code) ? this.data.purchase_code : '';
      return `${_invoice_label} - ${_customer_name} - ${_invoice_code}`;
    }
  },
  watch: {
    items_list: {
      handler(val) {
        if (val.length > 0) {
          this.items_total = this.$_.sumBy(this.items_list, 'subtotal');
          this.items_total = this.items_total ? this.items_total : 0;
          this.total = this.items_total;

        }
      },
      deep: true
    }
  },

  methods: {
    ...dataTypeNumbers,

    formatNumber(number) {
      // Format the number with commas if it's greater than 5 digits
      return number > 9999 ? new Intl.NumberFormat().format(number) : number;
    },

    printInvoice() {
      let _id = 'invoice';
      this.exportPDF(_id);
    },
    completedForPrint() {
      let imgs = document.images,
          len = imgs.length,
          counter = 0;

      [].forEach.call(imgs, function (img) {
        if (img.complete)
          incrementCounter();
        else
          img.addEventListener('load', incrementCounter, false);
      });

      function incrementCounter() {
        counter++;
        if (counter === len) {
          window.print();
          // window.close();
        }
      }

    },
    exportPDF(_id) {
      let that = this;
      if (that.type == 'pdf') {
        const opt = {
          pagebreak: {avoid: '.tr', mode: ['css']},
          margin: [5, 0, 5, 0],
          filename: this.fileName,
          image: {type: 'jpeg', quality: 2},
          html2canvas: {useCORS: true, scrollY: 0},
          jsPDF: {unit: 'mm', format: 'a4', orientation: 'portrait'}
        };
        let _html2pdf = html2pdf().from(document.getElementById(_id)).set(opt).save();
      } else {
        this.completedForPrint();
      }
    },
    async getData() {
      let _id = this.idEditing;
      await ApiService.get(`${this.mainRoute}/${_id}/print`).then((response) => {
        this.data = response.data.data;
        this.items_list = response.data.data.items_list;
        this.setTitleTab();
        this.show = true;

      });
    },
    setTitleTab() {
      let _invoice_label = this.$i18n.locale == 'ar' ? 'فاتورة مشتريات' : 'Purchase invoices';
      let _customer_name = (this.data.supplier && this.data.supplier.business_name) ? this.data.supplier.business_name : '';
      let _invoice_code = (this.data.purchase_code) ? this.data.purchase_code : '';
      document.title = `${_invoice_label} - ${_customer_name} - ${_invoice_code}`;
    },
    async printSetting() {
      await ApiService.get(`${this.templateMainRoute}/${this.template_for}`).then((response) => {
        this.template = response.data.data;
        this.content_temp = response.data.data.content_temp;
        this.product_table_setting = response.data.data.product_table_setting ? response.data.data.product_table_setting : [];
        this.is_multi_language_label = response.data.data.is_multi_language_label ? response.data.data.is_multi_language_label : false;
        this.is_break_before_footer = response.data.data.is_break_before_footer ? response.data.data.is_break_before_footer : false;
        this.is_payment_details = response.data.data.is_payment_details ? response.data.data.is_payment_details : false;
        this.is_banking_details = response.data.data.is_banking_details ? response.data.data.is_banking_details : false;
        this.is_repeated_header = response.data.data.is_repeated_header ? response.data.data.is_repeated_header : false;
        this.is_repeated_footer = response.data.data.is_repeated_footer ? response.data.data.is_repeated_footer : false;
        this.is_empty_row = response.data.data.is_empty_row ? response.data.data.is_empty_row : false;
        this.is_signature = response.data.data.is_signature ? response.data.data.is_signature : false;
        this.footer_font_color = response.data.data.footer_font_color ? response.data.data.footer_font_color : '#000';
      });
    },
    inContentTemp(content) {
      if (this.content_temp.includes(content))
        return true;
      else
        return false;
      return true
    },
    inProductTableSetting(content) {
      if (this.product_table_setting.includes(content))
        return true;
      else
        return false;
      return true
    },
    getSubtotalAfterTax(row) {
      let _sum = ((parseFloat(row.unit_price ? row.unit_price : 0) * parseFloat(row.qty ? row.qty : 0)) + parseFloat(row.amount_excluded_tax ? row.amount_excluded_tax : 0))
      return _sum.toFixed(2);
    },
  },
  mounted() {
    let promise = this.getData();
    let promise1 = this.printSetting();
    Promise.all([promise, promise1]).then(() => {
      this.printInvoice();
    });
    // window.print();
  },
  created() {


  }
}
</script>

<style>

.d-flex {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}

.title-1 {
  font-size: 39px;
  color: #1a5a4a;
}

.title-2 {
  font-size: 30px;
  color: #8898b2;
  padding-top: 8px;
}

.logo-w {
  width: 162px;
  margin-left: 77px;
}

.color-1a5a4a {
  color: #1a5a4a;
}

.green-cc {
  border: 2px solid #1a5a4a;
  padding: 2px 8px;
  border-radius: 10px;
  text-align: center;
}

.green-cc-3 {
  border: 2px solid #1a5a4a;
  padding: 10px 15px;
  border-radius: 10px;
  min-height: 70%;
}

.green-cc-2 {
  border: 2px solid #1a5a4a;
  padding: 6px 15px;
  border-radius: 10px;
}

.f-s-22px {
  font-size: 22px;
}

.f-s-19px {
  font-size: 19px;
}

.h-16px {
  line-height: 1.3;
}

.f-s-20px {
  font-size: 20px;
  font-weight: 400;
}

.f-s-15px {
  font-size: 15px;
  font-weight: 400;
}

.w-60per {
  width: 100%;
  /*display: inline-block;*/
}

.w-40per {
  width: 34%;
  display: inline-block;
}

.padding-25-15 {
  padding: 21px 15px !important;
}

.padding-b-15 {
  margin-bottom: 15px;
}

.fs-12 {
  font-size: 12px !important;
}

.word-spacing-4px {
  word-spacing: 4px;
}

.par-h {
  width: 1.5px;
  margin-left: 10px;
  background-color: #8898b2;
  display: block;
}

.qr-border {
  border: 2px solid #1a5a4a !important;
  padding: 5px;
  border-radius: 5px;
}

.total-border {
  border: 2px solid #1a5a4a !important;
  border-radius: 5px;
}

.w-330 {
  width: 55%;
}

.padding-5-20 {
  padding: 2px 5px !important;
}

.border-r-2 {
  border-right: 2px solid !important;
}

.rtl-dir {
  direction: rtl !important;
  float: right !important;
  display: flex !important;
}

#invoice {
  font-family: "AlHurra";
  width: 235mm;
  font-weight: 400 !important;
}

/*.font-family-Tajawal {*/
/*  font-family: "Roboto", sans-serif !important;*/
/*  word-spacing: initial !important;*/
/*}*/
/*#invoice * {*/
/*  word-spacing: 1px !important;*/
/*}*/

/*#invoice .space-word-2{*/
/*  word-spacing: 10px !important;*/
/*}*/
/*#invoice .space-word-15{*/
/*  word-spacing: 26px !important;*/
/*}*/
/*#invoice .space-latter-2{*/
/*  letter-spacing: 1px !important;*/
/*}*/
/*table, thead, tr, th, tbody, td {*/
/*  border: 2px solid #1a5a4a !important;*/
/*}*/

table thead tr th {
  border-bottom: 2px solid #1a5a4a !important;
}

table tbody {
  font-size: 14px !important;
}

table thead tr th:not(:last-child) {
  border-left: 2px solid #1a5a4a !important;
}

table tbody tr td:not(:last-child) {
  border-left: 2px solid #1a5a4a !important;
}

.table-border-radius {
  border-radius: 10px;
  overflow: hidden;
  border: 2px solid #1a5a4a !important;
}

tbody td, tbody tr {
  border-bottom: 0 !important;
  border-top: 0 !important;
}

.invoice-box {
  /*background-color: #fff;*/

  /*margin: auto;*/
  /*padding: 30px;*/
  border: 1px solid #fff;
  font-size: 16px;
  line-height: 24px;
  font-family: 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
  color: #000;


  /* padding: 65px 40px; */
  background-color: rgb(255, 255, 255);
  width: 210mm !important;
  height: auto;
  /* margin: 20px auto; */
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 5px 20px;

}

.invoice-box table {
  width: 100%;
  line-height: inherit;
  text-align: left;
}

.invoice-box table td {
  /* padding: 5px; */
  vertical-align: top;
}

.invoice-box table tr td:nth-child(2) {
  text-align: right;
}

.invoice-box table tr td:nth-child(3) {
  text-align: right;
}

.invoice-box table tr.top td.title {
  font-size: 45px;
  line-height: 45px;
  color: #000;
}

.sum-sum {
  text-align: center;
  margin-left: 15px;
  min-width: 60px;
}

.padd {
  padding-bottom: 100px;

}

.invoice-box table tr.information td {
  padding-bottom: 40px;
}

.invoice-box table tr.heading td {
  border: 1px solid #000 !important;
  background: #CAD3C8;
  border-bottom: 1px solid #fff;
  font-weight: bold;
}

.border {
  border: 1px solid #000 !important;
  padding: 5px;
}

.color {
  background-color: #CAD3C8;
}

.invoice-box table tr.details td {
  padding-bottom: 20px;
}

.invoice-box table tr.item td {
  border-bottom: 1px solid #eee;
}

.invoice-box table tr.item.last td {
  border-bottom: none;
}

.invoice-box table tr.total td:nth-child(2) {
  border-top: 2px solid #eee;
  font-weight: bold;
}

@media only screen and (max-width: 600px) {
  .invoice-box table tr.top table td {
    width: 100%;
    display: block;
    text-align: center;
  }

  .invoice-box table tr.information td {
    width: 100%;
    display: block;
    text-align: center;
  }
}

/** RTL **/

.invoice-box.rtl {
  direction: rtl;
  font-family: Tahoma, 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
}

.invoice-box.rtl table {
  text-align: right;
}

.invoice-box.rtl table tr td:nth-child(2) {
  text-align: left;
}

.table thead th {
  font-size: 15px !important;
  font-weight: 400 !important;
  color: #1a5a4a;
  vertical-align: middle;
}

.dir-ltr-w-100 {
  direction: ltr;
  float: left;
  text-align: center;
  width: 100%;
}

.width-100 {
  width: 100% !important;
}

.table thead th, .table thead td {
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
}

.table th, .table td {
  padding: 0.3rem;
}

.acc-bank-border {
  margin-bottom: 3px;
  padding-bottom: 3px;
  border-bottom: 2px solid #1a5a4a;
}

.table tbody tr:not(:last-child) {
  border-bottom: 2px solid #1a5a4a !important;
}

body > * {
  float: right !important;
  direction: rtl !important;
  text-align: right;
}

html {
  direction: rtl !important;
}
</style>
